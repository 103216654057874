import DateTimeService from 'src/utils/DateTimeService';

const WarrantyCertificateFormat = (WarrantyData) => {
  const ownerDetails = [
    {
      header: 'Name',
      accessor: WarrantyData?.owner?.firstName
        ? `${WarrantyData?.owner?.firstName} ${WarrantyData?.owner?.lastName}`
        : '-'
    },
    {
      header: 'Email',
      accessor: WarrantyData?.owner?.email || '-'
    },
    {
      header: 'Phone',
      accessor: WarrantyData?.owner?.phone || '-'
    },
    {
      header: 'Address',
      accessor: WarrantyData?.owner?.address || '-'
    }
  ];
  const vehicleDetails = [
    {
      header: 'RTO Name',
      accessor: WarrantyData?.RTO?.name || '-'
    },
    {
      header: 'Registration No.',
      accessor: WarrantyData?.registrationNumber || '-'
    },
    {
      header: 'Registration Date',
      accessor: WarrantyData?.meta?.regDate
        ? DateTimeService.getDateString(WarrantyData?.meta?.regDate)
        : '-'
    },
    {
      header: 'Engine No.',
      accessor: WarrantyData?.engineNo || '-'
    },
    {
      header: 'Category',
      accessor: WarrantyData?.category || '-'
    },
    {
      header: 'Chassis No.',
      accessor: WarrantyData?.chassisNo || '-'
    },
    {
      header: 'Make',
      accessor: WarrantyData?.make || '-'
    },
    {
      header: 'Model',
      accessor: WarrantyData?.model || '-'
    },
    {
      header: 'Panic Button',
      accessor: WarrantyData?.device?.panicButtonCount || '-'
    }
  ];

  const WarrantyCertificateLayout = [
    {
      header: 'Vehicle Details',
      data: vehicleDetails
    },

    {
      header: 'Owner Details',
      data: ownerDetails
    }
  ];

  return WarrantyCertificateLayout;
};

export default WarrantyCertificateFormat;
