const id = 'demo';
const fullName = 'GPS Sit-in';
const name = 'GPS Sit-in';
const logo = 'src/assets/images/base.png';

const loginPageText = `
GPS tracker Sit in site. This site is to avail demonstration for custom flavours of the tracking dashboard.
`;

const site = 'https://traxsmart.in';

const prodUrl = 'https://swm-api.traxsmart.in/v1';

const locationApiKey =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiVkxURCIsImlhdCI6MTcxNDQ3MzQ5MH0.4SoKstY_LRuz9axnSzCfm7VI3OAcolIUzzT2tiolg9M';

const pallete = {
  primary: '#5569ff',
  secondary: '#6E759F',
  success: '#57CA22',
  warning: '#FFA319',
  error: '#FF1943',
  info: '#33C2FF',
  black: '#223354',
  white: '#ffffff',
  primaryAlt: '#000C57'
};

const config = {
  id,
  fullName,
  name,
  pallete,
  logo,
  loginPageText,
  site,
  prodUrl,
  locationApiKey,
  defaultPass: 'password1$'
};

export default config;
