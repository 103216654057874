import React from 'react';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@mui/material';

function ImageSectionLayout({ data }) {
  return (
    <Table sx={{ border: 'none' }}>
      <TableBody>
        {data
          ?.filter((item) => item?.accessor !== (null || undefined || ''))
          .reduce((result, item, index) => {
            if (index % 3 === 0) result.push([]);
            result[result.length - 1].push(item);
            return result;
          }, [])
          .map((chunk, chunkIndex) => (
            <TableRow key={chunkIndex}>
              {chunk.map((item, itemIndex) => (
                <TableCell sx={{ borderBottom: 'none' }} key={itemIndex}>
                  <img
                    style={{
                      objectFit: 'contain',
                      height: 200,
                      width: 300
                    }}
                    src={item?.accessor}
                    alt={item?.header}
                  />
                </TableCell>
              ))}
              {Array(3 - chunk.length)
                .fill(null)
                .map((_, fillerIndex) => (
                  <TableCell sx={{ borderBottom: 'none' }} key={fillerIndex} />
                ))}
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
}

export default ImageSectionLayout;
