const adminDivisionTypeOptions = ['ZONE', 'CIRCLE', 'WARD'];

const rollbackReasons = [
  'Unsold stock',
  'Faulty Device',
  'Unsatisfactory device performance'
];
const unmappingReasons = [
  'Unsold stock',
  'Unsatisfactory performance',
  'Vehicle Sold Off'
];
const rollbackStatus = ['NEW', 'DEFECTED'];
const tagTypeOptions = ['QR', 'RFID'];
const geoPointTypeOptions = ['POINT', 'POLYGON'];
const genderTypeOptions = ['MALE', 'FEMALE', 'OTHERS'];
const routeTypeOptions = ['SWM', 'TRANSPORT'];
const bloodGroupTypeOptions = [
  'A+',
  'A-',
  'B+',
  'B-',
  'O+',
  'O-',
  'AB+',
  'AB-'
];

const SimActivationApprovalStatusType = [
  'INITIATED',
  'APPROVED',
  'COMPLETED',
  'REJECTED'
];

const eSimProvers = ['TAISYS', 'VENTEL'];

const VehicleCategory = [
  'PERSON',
  'BUS',
  'TRUCK',
  'SMALL_CAR',
  'BIKE',
  'MINI_VAN',
  'SEDAN',
  'SHIP',
  'SUV'
];

const VehicleStatus = ['ACTIVE', 'INACTIVE'];
const Language = ['en', 'hi'];
const FuelType = [
  'PETROL',
  'DIESEL',
  'CNG',
  'ELECTRIC',
  'LPG',
  'Ethanol',
  'Hydrogen'
];
const ROLES = ['ADMIN', 'DISTRIBUTER', 'DEALER', 'CUSTOMER', 'VENDOR'];

const ALERT_TYPES = [
  { keys: 'general', values: 'General' },
  { keys: 'sos', values: 'SOS' },
  { keys: 'vibration', values: 'Vibration' },
  { keys: 'movement', values: 'Movement' },
  { keys: 'lowspeed', values: 'Low Speed' },
  { keys: 'overspeed', values: 'Overspeed' },
  { keys: 'fallDown', values: 'Fall Down' },
  { keys: 'lowPower', values: 'Low Power' },
  { keys: 'lowBattery', values: 'Low Battery' },
  { keys: 'fault', values: 'Fault' },
  { keys: 'powerOff', values: 'Power Off' },
  { keys: 'powerOn', values: 'Power On' },
  { keys: 'door', values: 'Door' },
  { keys: 'lock', values: 'Lock' },
  { keys: 'unlock', values: 'Unlock' },
  { keys: 'geofence', values: 'Geofence' },
  { keys: 'geofenceEnter', values: 'Geofence Enter' },
  { keys: 'geofenceExit', values: 'Geofence Exit' },
  { keys: 'gpsAntennaCut', values: 'GPS Antenna Cut' },
  { keys: 'accident', values: 'Accident' },
  { keys: 'tow', values: 'Tow' },
  { keys: 'idle', values: 'Idle' },
  { keys: 'highRpm', values: 'High RPM' },
  { keys: 'hardAcceleration', values: 'Hard Acceleration' },
  { keys: 'hardBraking', values: 'Hard Braking' },
  { keys: 'hardCornering', values: 'Hard Cornering' },
  { keys: 'laneChange', values: 'Lane Change' },
  { keys: 'fatigueDriving', values: 'Fatigue Driving' },
  { keys: 'powerCut', values: 'Power Cut' },
  { keys: 'powerRestored', values: 'Power Restored' },
  { keys: 'jamming', values: 'Jamming' },
  { keys: 'temperature', values: 'Temperature' },
  { keys: 'parking', values: 'Parking' },
  { keys: 'bonnet', values: 'Bonnet' },
  { keys: 'footBrake', values: 'Foot Brake' },
  { keys: 'fuelLeak', values: 'Fuel Leak' },
  { keys: 'tampering', values: 'Tampering' },
  { keys: 'removing', values: 'Removing' },
  { keys: 'ignitionOn', values: 'Ignition On' },
  { keys: 'ignitionOff', values: 'Ignition Off' },
  { keys: 'acOn', values: 'AC turned on' },
  { keys: 'acOff', values: 'AC turned off' }
];
const ScheduleTypeOptions = [
  {
    value: 'O',
    label: 'One Time'
  },
  {
    value: 'R',
    label: 'Recurring'
  }
];

const scheduleFreqOptions = [
  {
    value: 'daily',
    label: 'Every day'
  },
  {
    value: 'weekly',
    label: 'Every week'
  },
  {
    value: 'monthly',
    label: 'Every month'
  },
  {
    value: 'yearly',
    label: 'Every year'
  },
  {
    value: 'custom',
    label: 'Custom'
  }
];

const orgTypes = ['MANUFACTURER', 'INVENTORY_MANAGER', 'RFC', 'CUSTOM'];
const parentRoles = ['RFC_ADMIN', 'MANUFACTURER', 'INVENTORY_MANAGER'];

const SimActivationStatusTypes = [
  'INITIATED',
  'APPROVED',
  'COMPLETED',
  'REJECTED'
];

const rollBackTypes = ['LAST_ORGANISATION', 'SOURCE_ORGANISATION'];
const deviceStatus = ['NEW', 'ALLOCATED', 'INSTALLED', 'DEFECTED'];

const rawLogToLabelMapping = {
  'attributes.vendor': 'Vendor ID',
  'attributes.versionFw': 'Firmware Version',
  'attributes.packetType': 'Packet Type',
  'attributes.alarm': 'Alarm',
  'attributes.regNo': 'Vehicle Reg No',
  'attributes.sat': 'No Of Satellites',
  'attributes.motion': 'Motion Status', // confirm
  'attributes.ignition': 'IgnitionStatus',
  'attributes.battery': 'Internal Battery Voltage',
  'attributes.power': 'Main Input Voltage',
  latitude: 'Latitude',
  longitude: 'Longitude',
  altitude: 'Altitude (in meters)',
  speed: 'Speed (in km/hrs)',
  outdated: 'Outdated',
  valid: 'GPS Fix',
  course: 'Heading',
  network: 'Network',
  'attributes.adc1': 'Analog Input 1',
  'attributes.adc2': 'Analog Input 2',
  'attributes.di1': 'Digital Input 1',
  'attributes.ac': 'Digital Input 2',
  'attributes.di3': 'Digital Input 3',
  'attributes.di4': 'Digital Input 4',
  'attributes.event': 'Alert ID',
  uniqueId: 'IMEI',
  'attributes.pdop': 'PDOP',
  'attributes.hdop': 'HDOP',
  'attributes.operator': 'Network Operator Name',
  'attributes.charge': 'Main Power Status',
  'attributes.emergency': 'Emergency Status',
  'attributes.tamper': 'Tamper Alert',
  'attributes.rssi': 'Signal Strength', // not coming
  'attributes.DO1': 'Digital Output 1',
  'attributes.DO2': 'Digital Output 2',
  'attributes.response': 'Command Response',
  'attributes.distance': 'Distance',
  'attributes.totalDistance': 'Total Distance',
  'attributes.hours': 'Hours',
  'attributes.archive': 'History',
  'attributes.door': 'Door'
};

const fieldsToExcludeFromRawFields = new Set([
  'attributes.totalDistance',
  'attributes.hours',
  'network.radioType',
  'network.considerIp'
]);

const labelsOfRawLogs = [
  'Start Character',
  'Header',
  'Vendor ID',
  'Firmware Version',
  'Packet Type',
  'Alert ID',
  'Packet Status',
  'IMEI',
  'Vehicle Reg No',
  'GPS Fix',
  'Date (DDMMYYYY)',
  'Time (hhmmss) in UTC',
  'Latitude',
  'Latitude Direction',
  'Longitude',
  'Longitude Direction',
  'Speed (in km/hrs)',
  'Heading',
  'No Of Satellites',
  'Altitude (in meters)',
  'PDOP',
  'HDOP',
  'Network Operator Name',
  'IgnitionStatus',
  'Main Power Status',
  'Main Input Voltage',
  'Internal Battery Voltage',
  'Emergency Status',
  'Tamper Alert',
  'GSM Signal Strength',
  'MCC',
  'MNC',
  'LAC',
  'CellID',
  'CellID Neighbour 1st',
  'LAC Neighbour 1st',
  'Signal Strength Neighbour 1st',
  'CellID Neighbour 2nd',
  'LAC Neighbour 2nd',
  'Signal Strength Neighbour 2nd',
  'CellID Neighbour 3rd',
  'LAC Neighbour 3rd',
  'Signal Strength Neighbour 3rd',
  'CellID Neighbour 4th',
  'LAC Neighbour 4th',
  'Signal Strength Neighbour 4th',
  'Digital Input Status',
  'Digital Output Status',
  'Frame Number',
  'Checksum',
  'End Character'
];

const duration = { '1 year': 365, '2 year': 730 };
export {
  duration,
  rollbackReasons,
  unmappingReasons,
  rollbackStatus,
  adminDivisionTypeOptions,
  tagTypeOptions,
  geoPointTypeOptions,
  genderTypeOptions,
  bloodGroupTypeOptions,
  routeTypeOptions,
  VehicleCategory,
  VehicleStatus,
  FuelType,
  ROLES,
  ALERT_TYPES,
  Language,
  ScheduleTypeOptions,
  scheduleFreqOptions,
  orgTypes,
  parentRoles,
  SimActivationApprovalStatusType,
  SimActivationStatusTypes,
  eSimProvers,
  rollBackTypes,
  deviceStatus,
  rawLogToLabelMapping,
  fieldsToExcludeFromRawFields
};
