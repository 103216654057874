import {
  Tooltip,
  Badge,
  tooltipClasses,
  styled,
  useTheme,
  Avatar,
  Typography
} from '@mui/material';
import { grey } from '@mui/material/colors';
import BusinessIcon from '@mui/icons-material/Business';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import AppConfig from 'src/flavours/index.flavour';
import { useEffect, useState } from 'react';
import OrganisationService from 'src/services/Organisation.service';
import useAuth from 'src/hooks/useAuth';
import OrganisationAssociationService from 'src/services/OrganisationAssociation.service';

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: transparent;
        display: flex;
        text-decoration: none;
        margin: 0 10px;
        margin-left: 10px;
        font-weight: ${theme.typography.fontWeightBold};
        justify-content: center;
        align-items: center;
        height: 55px;
        overflow: hidden;
`
);

const TooltipWrapper = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.alpha.trueWhite[100],
    color: theme.palette.getContrastText(theme.colors.alpha.trueWhite[100]),
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 'bold',
    borderRadius: theme.general.borderRadiusSm,
    boxShadow:
      '0 .2rem .8rem rgba(7,9,25,.18), 0 .08rem .15rem rgba(7,9,25,.15)'
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.alpha.trueWhite[100]
  }
}));

function Logo() {
  const { t } = useTranslation();
  const theme = useTheme();
  const [logo, setLogo] = useState('');
  const [name, setName] = useState('');
  const { user } = useAuth();

  const setLogoAndName = async () => {
    try {
      const { data: orgData } = await OrganisationService.getById(
        user?.organisation
      );

      if (orgData?.type === 'MANUFACTURER') {
        setLogo(orgData?.logo || `/static/images/logos/${AppConfig.id}.png`);
        setName(orgData?.brandName || AppConfig.name);
      } else {
        // find parent manufacturer and set logo
        const { data: association } = await OrganisationAssociationService.get({
          childOrganisationId: orgData?.id,
          parentOrgType: 'MANUFACTURER'
        });
        const parentManufacturer =
          association.length > 0 ? association[0]?.parentOrganisationId : null;
        if (parentManufacturer) {
          const { data } = await OrganisationService.getById(
            parentManufacturer
          );
          setLogo(data?.logo || `/static/images/logos/${AppConfig.id}.png`);
          setName(data?.brandName || AppConfig.name);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  // useEffect(() => {
  //   // if no org , set from flavour logo
  //   if (!user?.organisation) {
  //     setLogo(`/static/images/logos/${AppConfig.id}.png`);
  //     setName(AppConfig.name);
  //     return;
  //   }
  //   setLogoAndName();
  // }, [user]);

  return (
    <TooltipWrapper
      title={
        // name
        AppConfig.name
      }
      arrow
    >
      <LogoWrapper to="/">
        <img
          style={{
            objectFit: 'contain',
            maxWidth: '80%',
            maxHeight: '80%',
            height: 'auto',
            width: 'auto'
          }}
          src={
            // organisation?.attributes?.logo ||
            // logo
            `/static/images/logos/${AppConfig.id}.png`
          }
          alt={`${AppConfig.name} logo`}
        />

        <Typography
          sx={{
            ml: 1,
            fontSize: '1.5em',
            fontWeight: 'bold',
            color: 'text.secondary'
          }}
        >
          {
            // name
            AppConfig.name
          }
        </Typography>
      </LogoWrapper>
    </TooltipWrapper>
  );
}

export default Logo;
