import axios from 'axios';
import _, { head } from 'lodash';
import DateTimeService from 'src/utils/DateTimeService';

const createDynamicImageDetails = (data, header, imagePath) => {
  const d = data?.map((imageData, index) => {
    const image = imagePath ? _.get(imageData, imagePath) : imageData;

    return {
      header: `${header} ${index}`,
      accessor: image
    };
  });
  return d;
};

const certificateDataFormat = (certificateData) => {
  const vehicleDetails = [
    {
      header: 'RTO Name',
      accessor: certificateData?.vehicle?.rto || '-'
    },
    {
      header: 'Registration No.',
      accessor: certificateData?.vehicle?.registrationNumber || '-'
    },
    {
      header: 'Registration Date',
      accessor: certificateData?.vehicle?.regDate
        ? DateTimeService.getDateString(certificateData?.vehicle?.regDate)
        : '-'
    },
    {
      header: 'Engine No.',
      accessor: certificateData?.vehicle?.engineNo || '-'
    },
    {
      header: 'Category',
      accessor: certificateData?.vehicle?.category || '-'
    },
    {
      header: 'Chassis No.',
      accessor: certificateData?.vehicle?.chassisNo || '-'
    },
    {
      header: 'Make',
      accessor: certificateData?.vehicle?.make || '-'
    },
    {
      header: 'Model',
      accessor: certificateData?.vehicle?.model || '-'
    }
  ];

  const deviceDetails = [
    {
      header: 'UID',
      accessor: certificateData?.device?.UID || '-'
    },
    {
      header: 'IMEI',
      accessor: certificateData?.device?.imei || '-'
    },
    {
      header: 'Panic Button',
      accessor: certificateData?.device?.panicButtonCount || '-'
    },
    {
      header: 'Model',
      accessor: certificateData?.device?.model || '-'
    },
    {
      header: 'Sim 1',
      accessor: certificateData?.device?.sim1 || '-'
    },
    {
      header: 'Sim 2',
      accessor: certificateData?.device?.sim2 || '-'
    },
    {
      header: 'Sim Activation Date',
      accessor: certificateData?.fitment?.date
        ? DateTimeService.getDateString(certificateData?.fitment?.date)
        : '-'
    },
    {
      header: 'Installation Date',
      accessor: certificateData?.fitment?.date
        ? DateTimeService.getDateString(certificateData?.fitment?.date)
        : '-'
    },
    {
      header: 'Expires On',
      accessor: certificateData?.fitment?.expiresOn
        ? DateTimeService?.getDateString(certificateData?.fitment?.expiresOn)
        : '-'
    },
    {
      header: 'TAC',
      accessor: certificateData?.device?.TAC || '-'
    },
    {
      header: 'ICCID',
      accessor: certificateData?.device?.iccid || '-'
    }
  ];

  const ownerDetails = [
    {
      header: 'Name',
      accessor: certificateData?.owner?.firstName
        ? `${certificateData?.owner?.firstName} ${certificateData?.owner?.lastName}`
        : '-'
    },
    {
      header: 'Email',
      accessor: certificateData?.owner?.email || '-'
    },
    {
      header: 'Phone',
      accessor: certificateData?.owner?.phone || '-'
    },
    {
      header: 'Address',
      accessor: certificateData?.owner?.address || '-'
    }
  ];

  const vendorDetails = [
    {
      header: 'Name',
      accessor: certificateData?.vendor?.brandName || '-'
    },
    {
      header: 'Email',
      accessor: certificateData?.vendor?.email || '-'
    },
    {
      header: 'Phone',
      accessor: certificateData?.vendor?.contact || '-'
    },
    {
      header: 'Address',

      accessor: (() => {
        const {
          addressLine1,
          addressLine2,
          pincode,
          area,
          city,
          state,
          country
        } = certificateData?.vendor?.address ?? {};
        return [addressLine1, addressLine2, pincode, area, city, state, country]
          .filter(Boolean)
          .join(', ');
      })()
    }
  ];

  const fitterDetails = [
    {
      header: 'Name',
      accessor: certificateData?.device?.fitment?.by?.firstName
        ? `${certificateData?.device?.fitment?.by?.firstName} ${certificateData?.device?.fitment?.by?.lastName}`
        : '-'
    },
    {
      header: 'Email',
      accessor: certificateData?.device?.fitment?.by?.email || '-'
    },
    {
      header: 'Phone',
      accessor: certificateData?.device?.fitment?.by?.phone || '-'
    },
    {
      header: 'Address',
      accessor: certificateData?.device?.fitment?.by?.address || '-'
    }
  ];

  const certificateLayout = [
    {
      header: 'Vehicle Details',
      data: vehicleDetails
    },

    {
      header: 'Device Details',
      data: deviceDetails
    },

    {
      header: 'Vendor Details',
      data: vendorDetails
    },
    {
      header: 'Owner Details',
      data: ownerDetails
    },

    {
      header: 'Fitter Details',
      data: fitterDetails
    },
    {
      header: 'RC Images',
      data: createDynamicImageDetails(
        certificateData?.vehicle?.rcImages,
        'RC Image'
      ),
      type: 'Image'
    },
    {
      header: 'Device Images',
      data: createDynamicImageDetails(
        certificateData?.device?.deviceImages,
        'Device Image'
      ),
      type: 'Image'
    },
    {
      header: 'KYC Images',
      data: createDynamicImageDetails(
        certificateData?.owner?.kycDocs,
        'KYC Image',
        'link'
      ),
      type: 'Image'
    }
  ];

  return certificateLayout;
};

export default certificateDataFormat;
