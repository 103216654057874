import { CERTIFICATE_URL } from 'src/config/api.config';
import ApiService from './Api.service';

class CertificateService extends ApiService {
  constructor() {
    super(CERTIFICATE_URL);
  }

  getByUrlId = (id) => {
    return this.doGet(`${CERTIFICATE_URL}/${id}`, {}, {});
  };
  getQuickCertificateData=(params)=>{
    return this.doGet(`${CERTIFICATE_URL}/quick`,{ ...params });
  };
}

export default new CertificateService();
