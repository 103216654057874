import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';
import ModuleBundle from 'src/config/models/ModuleBundle';

export const DashboardModule = new Module({
  id: 'admin-dashboard',
  name: 'Dashboard',
  label: 'Dashboard',
  path: 'dashboard',
  Component: ComponentLoader(
    lazy(() => import('src/content/dashboards/AIS140'))
  )
});

export const DashboardNewModule = new Module({
  id: 'dashboardv2',
  name: 'Dashboard 2.0',
  label: 'Dashboard 2.0',
  path: 'dashboardv2',
  isNew: true,
  Component: ComponentLoader(lazy(() => import('./DashboardV2')))
});

export const FleetDashboardModule = new Module({
  id: 'fleet-dashboard',
  name: 'Fleet Dashboard',
  label: 'Fleet Dashboard',
  path: 'fleet-dashboard',
  isBeta: true,
  Component: ComponentLoader(lazy(() => import('./FleetDashboard')))
});

const DashboardModules = [DashboardModule, DashboardNewModule];
export default new ModuleBundle('Dashboard', DashboardModules);
