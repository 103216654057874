import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';
import ModuleBundle from 'src/config/models/ModuleBundle';

export const DebugViewModule = new Module({
  id: 'debug',
  name: 'Debug',
  label: 'Debug',
  path: 'debug',
  Component: ComponentLoader(
    lazy(() => import('src/content/management/Debug/View'))
  ),
  actions: {
    addTemporaryDevice: {
      id: 'add-temporary-device',
      name: 'addTemporaryDevice',
      label: 'Add Device'
    }
  }
});

const DebugModules = [DebugViewModule];

export default new ModuleBundle('Debug-Logs', DebugModules);
