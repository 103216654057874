import { Box, styled, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AppConfig from 'src/flavours/index.flavour';

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        padding: ${theme.spacing(0, 1, 0, 0)};
        display: flex;
        text-decoration: none;
        font-weight: ${theme.typography.fontWeightBold};
        max-width: 35%;
        aspect-ratio: 1;
        overflow: hidden;
        align-items: center;
        justify-content: center;
`
);

function Logo() {
  const { t } = useTranslation();

  return (
    <LogoWrapper to="/">
      <img
        style={{
          objectFit: 'contain',
          maxWidth: '100%',
          maxHeight: '100%',
          height: 'auto',
          width: 'auto'
        }}
        src={`/static/images/logos/${AppConfig.id}.png`}
        alt={`${AppConfig.name} logo`}
      />
    </LogoWrapper>
  );
}

export default Logo;
