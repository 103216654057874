import DeviceDetailsModules from 'src/content/management/DeviceDetails/module';
import DeviceModules from 'src/content/management/Devices/module';
import DeviceModelModules from 'src/content/management/DeviceModel/module';
import registerModulesWithRouter from 'src/config/AccessControl/registerModulesWithRouter';
import VehicleModules from 'src/content/management/Vehicles/module';
import GeofenceModules from 'src/content/management/GeoFences/module';
import UserModules from 'src/content/management/Users/module';
import POIModules from 'src/content/management/POI/module';
import RoleModules from 'src/content/management/Role/module';
import ItemModules from 'src/content/management/Item/module';
import JobModules from 'src/content/management/Job/module';
import OrganisationModules from 'src/content/management/Organisation/module';
import WalletModules from 'src/content/management/Wallet/module';
import SettingModules from 'src/content/management/Setting/module';
import VehicleStatusDashboardModules from 'src/content/dashboards/VehicleStatus/module';
import GroupsModules from 'src/content/management/Groups/module';
import RouteModules from 'src/content/management/Routes/module';
import AdminDivisionModules from 'src/content/management/AdminDivision/module';
import DebugModules from 'src/content/management/Debug/module';
import { privateCertificateRoutes } from './certificate.route';

// Management

const managementRoutes = [
  registerModulesWithRouter(DeviceDetailsModules),
  registerModulesWithRouter(DeviceModules),
  registerModulesWithRouter(DeviceModelModules),
  registerModulesWithRouter(GeofenceModules),
  registerModulesWithRouter(RoleModules),
  registerModulesWithRouter(OrganisationModules),
  // registerModulesWithRouter(ItemModules),
  registerModulesWithRouter(VehicleModules),
  registerModulesWithRouter(VehicleStatusDashboardModules),
  registerModulesWithRouter(UserModules),
  registerModulesWithRouter(JobModules),
  registerModulesWithRouter(SettingModules),
  // registerModulesWithRouter(WalletModules),
  registerModulesWithRouter(GroupsModules),
  registerModulesWithRouter(POIModules),

  registerModulesWithRouter(RouteModules),
  registerModulesWithRouter(AdminDivisionModules),
  registerModulesWithRouter(DebugModules),
  {
    path: '/certificate',
    children: privateCertificateRoutes
  }
];

export default managementRoutes;
